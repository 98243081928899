import React from "react";
import Helmet from "react-helmet";

const SEO_DATA = {
  description:
    "We offer a full product development and engineering service, working alongside founders and teams to help them create, develop and launch their ventures.",
  title: "|> QUUACK - Product Development",
  url: "https://www.quuack.com",
  author: "QUUACK S.A.",
  keywords: [
    "User experience (UX) design",
    "Rapid prototyping",
    "ETL automation",
    "Machine learning",
    "E-commerce analytics",
    "React Native Application development",
    "Drupal CMS",
    "GatsbyJS",
    "Wordpress CMS",
    "ElectronJS",
    "Front-end development",
    "Back-end development",
    "Accesibility Reports"
  ],
  twitter: {
    id: "",
    img: ""
  },
  facebook: {
    id: "",
    img: ""
  }
};

const SEO = () => {
  return (
    <Helmet>
      <meta property="fb:app_id" content={SEO_DATA.facebook.id} />
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:image" content={SEO_DATA.facebook.img} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@" />
      <meta name="twitter:site" content="@" />
      <meta name="twitter:title" content={SEO_DATA.title} />
      <meta name="twitter:description" content={SEO_DATA.description} />
      <meta name="twitter:domain" content={SEO_DATA.url} />
      <meta name="twitter:image:src" content={SEO_DATA.img} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(", ")} />
      <meta name="author" content={SEO_DATA.author} />
      <title>{SEO_DATA.title}</title>
      <html lang="en" />
    </Helmet>
  );
};

export default SEO;
