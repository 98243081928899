const theme = {
  font: {
    primary: `'Fira Sans', sans-serif`,
    secondary: `'Lora', serif`
  },
  font_size: {
    xxsmall: "font-size: 1rem; line-height: 2rem",
    xsmall: "font-size: 1.5rem; line-height: 2.2rem",
    small: "font-size: 1.8rem; line-height: 2.5rem",
    regular: "font-size: 2.4rem; line-height: 3.2rem",
    large: "font-size: 3rem; line-height: 4rem",
    larger: "font-size: 3.6rem; line-height: 4.8rem",
    xlarge: "font-size: 4.8rem; line-height: 5.6rem",
    xxlarge: "font-size: 5.6rem; line-height: 6.4rem"
  },
  color: {
    white: {
      regular: "#FFFFFF",
      dark: "#F6F6F6"
    },
    black: {
      lighter: "#ABA8AF",
      light: "#564F62",
      regular: "#211E26"
    },
    blue: {
      dark: "#1f2d59"
    },
    primary: "#1f2d59"
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px"
  }
};

export default theme;
